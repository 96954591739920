import React, { useContext, useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { ArrowBack, ArrowForward, Margin } from "@mui/icons-material";
import moment from "moment-timezone";

import {
  Box,
  useTheme,
  //FormControl,
  IconButton,

} from "@mui/material";

import { useGetPaymentMethodsQuery } from "features/paymentPlan/paymentPlanApiSlice";
import { colors } from "constants/themeConstants";

import {
  useGetSpecialServiceQuery,
  useGetTicketBundlesQuery,
  useGetEventTicketQuery,
} from "features/ticket/ticketApiSlice";
//import { localization } from "constants/localalization";
import { StyledLinearProgress } from "pages/EventDetail/styles";
import { FeaturedTitle } from "components/Cards/FeaturedEventCard/Styles";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { LanguageContext } from "hooks/useLanguage";
import { CurrencyContext } from "hooks/useCurrency";
import { styled } from '@mui/system';
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import { localization } from "constants/localalization";
import { FormikSelectScheduleField } from "components/formComponents/CustomFormikFields";
import useGetDataPagination from "hooks/useGetDataPagination";
import Marquee from "react-fast-marquee";
import { useGetEventScheduleQuery } from "features/events/eventsApiSlice";
import { useGetEventScheduleCalanderQuery } from "features/events/eventsApiSlice";
import { useGetEventQuery } from "features/events/eventsApiSlice";
import { format } from "date-fns";
import { toDate, formatInTimeZone } from "date-fns-tz";

import Calendar from "react-calendar";
import { makeStyles } from "@mui/styles";
import {
  Container,

  Paper,
 
} from "@mui/material";
import "react-calendar/dist/Calendar.css";



const useStyles = makeStyles((theme) => ({


}));

function PriceSelect({
  price,
  ticket,
  tickets,
  eventId,
  setPrice,
  setBundle,
  setCurrency,
  currencyy,
  setTicket,
  isRecurring,
  scheduleId,
  snapshot,
}) {
  const classes = useStyles();
  const { currency } = useContext(CurrencyContext);
  function roundToTwoDecimalPlaces(number) {
    // console.log(number);
    return Number(number.toFixed(2));
  }
  const [isFirst, setIsFirst]= useState(true)
  const [visible, setVisible] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const contentRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { language } = useContext(LanguageContext);
  const handleInfoClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  // console.log(selectedScheduleId);
  const { data: specialService, isLoading } = useGetSpecialServiceQuery({
    id: eventId,
  });
  const { data: event, isError, error } = useGetEventQuery({ id: eventId });
  console.log(event?.timezone);
  const { data } = useGetPaymentMethodsQuery();
  const theme = useTheme();
  const {
    isLoading: isLoadingSchedule,
    pagination: paginationSchedule,
    setPagination: setPaginationSchedule,
    rowCount: rowCountSchedule,
    data: schedule,
    setEventId: setEventIdSchedule,
  } = useGetDataPagination()(useGetEventScheduleQuery);
  const { data: calendarSchedule, error:scheduleError, isLoading:loading, isSuccess:calendarSucess } = useGetEventScheduleCalanderQuery({ id: eventId });

  useEffect(() => {
    setEventIdSchedule(eventId);
    
  }, []);

  
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState([]);
  useEffect(() => {
    if (calendarSucess) {
      const loadedEvents = calendarSchedule.flatMap((eventDay) =>
        eventDay.schedules.map((schedule) => ({
          dates: eventDay.date,
          id: schedule.id,
          title: schedule.name,
          date: new Date(eventDay.date + "T" + schedule.start_time),
        }))
      );
      setEvents(loadedEvents);
    
      setSelectedScheduleId(loadedEvents[0].id);
      localStorage.setItem("selectedScheduleId", loadedEvents[0].id);
      const defaultSchedules =    loadedEvents.filter(singleSchedule=>singleSchedule.dates ==loadedEvents[0].dates)
      setDefaultSchedule( defaultSchedules);
      setisDefaultSchedule(true)


   
      const today = new Date();
      const upcomingEvents = loadedEvents.filter((event) => event.date >= today);
  
      if (upcomingEvents.length > 0) {
        setSelectedDate(upcomingEvents[0].date);
        setSelectedEvents(upcomingEvents);
      }
    }
  }, [calendarSucess, calendarSchedule]);



  
  const StyledCalendar = styled(Calendar)(({ theme }) => ({
    width: "100%",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    border: "none !important",
    borderRadius: "16px",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
  
    "& .react-calendar__tile": {
      backgroundColor: "white !important",
      color: "grey",
      position: "relative",
      padding: "10px",
  
      "&.event": {
        "& .dot": {
          position: "absolute",
          bottom: "5px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "3px",
          height: "3px",
          borderRadius: "50%",
          zIndex: 1,
        
        },
  
        "&.eventCount-2 .dot:nth-child(1)": {
          left: "45%",
        },
        "&.eventCount-2 .dot:nth-child(2)": {
          left: "55%",
        },
        "&.eventCount-3 .dot:nth-child(1)": {
          left: "40%",
        },
        "&.eventCount-3 .dot:nth-child(2)": {
          left: "50%",
        },
        "&.eventCount-3 .dot:nth-child(3)": {
          left: "60%",
        },
      },
  
      "&.currentDate": {
        border: "0.5px solid grey",
        borderRadius: "20px",
      },
  
      "&.selected": {
        borderRadius: "60px",
        backgroundColor: "black !important",
        color: "white !important",
        zIndex: 0,
      },
  
      "&.currentDate.selected": {
        borderRadius: "60px",
        backgroundColor: "black !important",
        color: "white !important",
        zIndex: 0,
      },
  
      "&.event:not(.selected)": {
        backgroundColor: "white !important",
        color: "black !important",
      },
    },
  
    "& .react-calendar__month-view__weekdays": {
      backgroundColor: "white",
      color: "black",
    },
    "& .react-calendar__navigation button": {
      color: "black",
    },
  }));
  
  
  const handleDateClick = (date) => {
    
    const eventsOnDate = events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    );
    setisDefaultSchedule(false)
    setSelectedDate(date);
    setSelectedSchedule(eventsOnDate);
    setSelectedScheduleId(eventsOnDate[0].id);
console.log(eventsOnDate[0].id,'selected sechedule id')
   
  };

  const[isDefaultSchedule, setisDefaultSchedule]=useState(false)
  const[defaultSchedule, setDefaultSchedule]=useState()

  const handleScheduleClick = (scheduleId) => {
    setIsFirst(false)
    setSelectedScheduleId(scheduleId);
    localStorage.setItem("selectedScheduleId", scheduleId);
  };
  const [selectedScheduleId, setSelectedScheduleId] = useState();
  console.log(selectedScheduleId);
  const { data: bundles, isLoading: bundleLoading } = useGetTicketBundlesQuery({
    id: eventId,
    scheduleId: selectedScheduleId,
  });
  const { data: eventtickets, isLoading: ticketsloading } =
    useGetEventTicketQuery({
      id: eventId,
      scheduleId: selectedScheduleId,
    });
  const paymentMethods = data
    ? data.map((item) => ({
        label: item.logo_url,
        value: item.identifier,
      }))
    : [];
  console.log(tickets);
  console.log(scheduleId);

  useEffect(() => {
    const handleResizeAndScroll = () => {
      const container = contentRef.current;
      if (container) {
        setShowLeftArrow(container.scrollLeft > 0);
        setShowRightArrow(
          container.scrollWidth > container.clientWidth + container.scrollLeft
        );
      }
    };

    const container = contentRef.current;
    if (container) {
      handleResizeAndScroll();
      container.addEventListener("scroll", handleResizeAndScroll);
      window.addEventListener("resize", handleResizeAndScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleResizeAndScroll);
        window.removeEventListener("resize", handleResizeAndScroll);
      }
    };
  }, []);

  const handleScroll = (scrollOffset) => {
    const container = contentRef.current;
    if (container) {
      container.scrollTo({
        left: container.scrollLeft + scrollOffset,
        behavior: "smooth",
      });
    }
  };
  const eventTimezone = event?.timezone.split(" ")[0];
  const formatTime = (time, eventTimezone) => {
    const utcDate = toDate(`2000-01-01T${time}Z`);
    return formatInTimeZone(utcDate, eventTimezone, "hh:mm a");
  };
const renderDots = (eventCount) => {
  const dots = [];
  for (let i = 0; i < Math.min(eventCount, 3); i++) {
    dots.push(
      <div
        key={i}
        className="dot"
        style={{
          left: `${50 - (eventCount - 1) * 4 + i * 8}%`,
          transform: 'translateX(-50%)',
        }}
      />
    );
  }
  return dots;
}; 
  useEffect(() => {
    if (eventtickets && eventtickets.length > 0) {
      const firstTicket = eventtickets.find(
        (item) => item.is_visible && item.total_available_quantity > 0
      );
      if (firstTicket) {
        const matchingPrice = firstTicket.prices.find(
          (price) => price.currency === currency
        );
        if (matchingPrice) {
          setPrice(matchingPrice.price);
          setCurrency(matchingPrice.currency);
          setTicket(firstTicket.id);
        }
      }
    }
  }, [eventtickets, currency, setPrice, setCurrency, setTicket]);

  return (
    <React.Fragment>
      {isRecurring && (
        <Grid style={{ overflowX: "auto" }} ref={contentRef}>
          <Grid spacing={2} style={{ display: "inline-flex" }}>
   
               <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
       
<StyledCalendar
  value={selectedDate}
  onClickDay={handleDateClick}
  tileDisabled={({ date }) =>
    !events.some((event) => event.date.toDateString() === date.toDateString())
  }
  tileClassName={({ date }) => {
    const eventCount = events.filter(
      (event) => event.date.toDateString() === date.toDateString()
    ).length;
    const isSelectedDate =
      selectedDate && date.toDateString() === selectedDate.toDateString();
    const isCurrentDate = new Date().toDateString() === date.toDateString();

    let className = "";

    if (isCurrentDate) {
      className = "currentDate";
    }

    if (isSelectedDate) {
      className += " selected";
    }

    if (eventCount > 0) {
      className += " event";
      if (eventCount === 1) {
        className += " eventCount-1";
      } else if (eventCount === 2) {
        className += " eventCount-2";
      } else if (eventCount >= 3) {
        className += " eventCount-3";
      }
    }

    return className.trim();
  }}
  tileContent={({ date, view }) => {
    if (view === 'month') {
      const eventCount = events.filter(
        (event) => event.date.toDateString() === date.toDateString()
      ).length;
      const displayCount = Math.min(eventCount, 3);
      const isSelectedDate = selectedDate && date.toDateString() === selectedDate.toDateString();

      return (
        <div>
          {Array.from({ length: displayCount }, (_, i) => (
            <div key={i} className="dot" style={{ backgroundColor: isSelectedDate ? 'white' : 'black' }}></div>
          ))}
        </div>
      );
    }
  }}
/>



     
          </Grid>
          <Grid item xs={12} md={4}>
   
               <div className={classes.eventContainer}>
      {selectedSchedule.length > 0 && (
        <div className={classes.eventList}>
              <Typography
        color={colors.background.tertiary}
        fontFamily={"Comfortaa"}
        fontSize={"15px"}
        marginBottom={"15px"}
 
      >
        Available Schedule
 
      </Typography>
         
      <Grid container  sx={{height:"280px",width:"100%",overflowY: "auto",scrollbarWidth:"none"}} >
      
            {selectedSchedule.map(event => (
              <Grid item xs={12} key={event.id} marginBottom={"10px"} width={"100%"}>
                <Box
                 onClick={() => {
                  handleScheduleClick(event?.id);
                }}
                  sx={{
                    backgroundColor: selectedScheduleId === event?.id ?colors.background.secondary:colors.background.pureWhite,
                     // selectedScheduleId === event?.id ? "2px" : "0px"
                    // backgroundColor: colors.background.pureWhite,
                    borderRadius: '20px',
                    border: `1px solid ${colors.background.outline}`,
                    transition: 'background-color 0.3s',
                    '&:hover': {
                      backgroundColor: colors.background.secondary,
                      borderRadius: '20px',
                      cursor: 'pointer',
                    },
                   
                  }}
                  padding={1}
                  width="100%"
                >
               
                  <Grid container justifyContent="center" alignContent="center" alignItems="center">
                    <Grid item xs={12}>
                    <Marquee speed={30}>
                      <Typography variant="body1" sx={{ fontWeight: '300', fontSize: '18px', color: colors.background.tertiary }}>
                        {event.title}
                      </Typography>
                      </Marquee>
                   
                      <Typography variant="body2" sx={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '12px', color: colors.background.tertiary }}>
                        {format(event.date, 'MMM dd, yyyy')}
                      </Typography>
                      <Typography variant="body2" sx={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '12px', color: colors.background.tertiary }}>
                        {format(event.date, 'hh:mm a')} - {format(event.date, 'hh:mm a')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
     
      {isDefaultSchedule &&  (
                <div className={classes.eventList}>
                <Typography
          color={colors.background.tertiary}
          fontFamily={"Comfortaa"}
          fontSize={"15px"}
          marginBottom={"15px"}
   
        >
          Available Schedule
   
        </Typography>
           
        <Grid container  sx={{height:"280px",width:"100%",overflowY: "auto",scrollbarWidth:"none"}} >
        {defaultSchedule.map((event, index) => (
  <Grid item xs={12} marginBottom={"10px"} key={event.id}>
    <Box
      onClick={() => {
        handleScheduleClick(event.id);
      }}
      sx={{
        backgroundColor: index === 0 && isFirst || selectedScheduleId == event.id ? colors.background.secondary : colors.background.pureWhite,
        borderRadius: '20px',
        border: `1px solid ${colors.background.outline}`,
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: colors.background.secondary,
          borderRadius: '20px',
          cursor: 'pointer',
        },
        padding: 1,
        width: "100%",
      }}
    >
      <Grid container justifyContent="center" alignContent="center" alignItems="center">
        <Grid item xs={12}>
        <Marquee speed={30}>
                      <Typography variant="body1" sx={{ fontWeight: '300', fontSize: '18px', color: colors.background.tertiary }}>
                        {event.title}
                      </Typography>
                      </Marquee>
                   
                      <Typography variant="body2" sx={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '12px', color: colors.background.tertiary }}>
                        {format(event.date, 'MMM dd, yyyy')}
                      </Typography>
                      <Typography variant="body2" sx={{ fontFamily: 'Comfortaa', fontWeight: 300, fontSize: '12px', color: colors.background.tertiary }}>
                        {format(event.date, 'hh:mm a')} - {format(event.date, 'hh:mm a')}
                      </Typography>
        </Grid>
      </Grid>
    </Box>
  </Grid>
))}

           
               
              )
            </Grid>
          </div>
      )}
    </div>
          </Grid>
        </Grid>
      </Container>
    </div>
          </Grid>
        </Grid>
      )}
      <Typography
        color={colors.background.tertiary}
        fontFamily={"Comfortaa"}
        fontSize={"22px"}
        fontWeight={"700"}
      >
        Select a Ticket
 
      </Typography>

      <Grid spacing={2} container>
        {eventtickets?.map((item) => {
          if (item.is_visible && item.total_available_quantity > 0) {
            const matchingPrice = item.prices.find(
              (price) => price.currency === currency
            );
            if (matchingPrice) {
              return (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  key={item.id}
                  style={{
                    width: "auto",
                    flexShrink: "100 !important",
                  }}
                >
                  {item.sales_channel !== "DOOR_ONLY" ? (
                    <Box
                      sx={{
                        backgroundColor: colors.background.pureWhite,
                        borderRadius: "20px",
                        border:
                          ticket === item.id
                            ? `2px solid ${colors.background.secondary}`
                            : `1px solid ${colors.background.outline}`,
                        transition: "background-color 0.3s",
                        backgroundColor:
                          ticket === item.id
                            ? colors.background.secondary
                            : colors.background.pureWhite,
                        "&:hover": {
                          backgroundColor: colors.background.secondary,
                          borderRadius: "20px",
                          cursor: "pointer",
                        },
                      }}
                      padding={1}
                      width="100%"
                      onClick={() => {
                        setPrice(matchingPrice.price);
                        setCurrency(matchingPrice.currency);
                        setTicket(item.id);
                      }}
                      border="1px solid white"
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        justifyItems="center"
                        alignContent={"center"}
                        alignItems="center"
                      >
                        <Grid item xs={9}>
                          <FeaturedTitle
                            sx={{
                              color: colors.background.tertiary,
                              fontWeight: "300",
                              fontSize: "30px",
                            }}
                          >
                            {roundToTwoDecimalPlaces(matchingPrice.price) +
                              matchingPrice.currency}
                          </FeaturedTitle>
                          <Typography
                            fontFamily={"Comfortaa"}
                            fontWeight={300}
                            fontSize="14px"
                            sx={{
                              color: colors.background.tertiary,
                            }}
                          >
                            {item.name[language]}
                          </Typography>
                        </Grid>
                        <Grid
                          justifyContent={"center"}
                          justifyItems="center"
                          alignContent={"center"}
                          alignItems="center"
                          item
                          xs={3}
                        >
                          <IconButton
                            aria-label="filter"
                            sx={{
                              marginRight: 2,
                              backgroundColor: colors.background.primary,
                              color: colors.background.tertiary,
                              "&:hover": {
                                backgroundColor: colors.background.tertiary,
                                color: colors.background.primary,
                              },
                              ml: 1,
                            }}
                          >
                            <AiOutlineShoppingCart
                              sx={{
                                height: 32,
                                width: 32,
                              }}
                            />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        borderRadius: "20px",
                        border: `1px solid ${colors.background.outline}`,
                        transition: "background-color 0.3s",
                        "&:hover": {
                          backgroundColor: colors.background.secondary,
                          borderRadius: "20px",
                          cursor: "pointer",
                        },
                      }}
                      padding={1}
                      width="100%"
                    >
                      <Grid
                        container
                        justifyContent={"center"}
                        justifyItems="center"
                        alignContent={"center"}
                        alignItems="center"
                      >
                        <Grid item xs={9}>
                          <FeaturedTitle
                            sx={{
                              color: colors.background.tertiary,
                              fontWeight: "300",
                              fontSize: "30px",
                            }}
                          >
                            {item?.prices.length === 0
                              ? "FREE"
                              : roundToTwoDecimalPlaces(matchingPrice.price) +
                                matchingPrice.currency}
                          </FeaturedTitle>
                          <Typography
                            fontFamily={"Comfortaa"}
                            fontWeight={300}
                            fontSize="14px"
                            sx={{
                              color: colors.background.tertiary,
                            }}
                          >
                            {item.name[language]}
                          </Typography>
                        </Grid>
                        <Grid
                          justifyContent={"center"}
                          justifyItems="center"
                          alignContent={"center"}
                          alignItems="center"
                          item
                          xs={3}
                        >
                          <Typography
                            fontFamily={"Comfortaa"}
                            fontWeight={500}
                            fontSize="14px"
                            sx={{
                              color: colors.background.tertiary,
                            }}
                          >
                            Door Only
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
              );
            }
          }
          return null; // Return null if the item is not visible or total available quantity is 0
        })}
      </Grid>

      {bundles?.length > 0 && (
        <Grid
          container
          mt={4}
          justifyContent="space-between"
          alignItems="center"
          spacing={0}
        >
          <Typography
            color={colors.background.tertiary}
            fontFamily={"Comfortaa"}
            fontSize={"22px"}
            fontWeight={"700"}
          >
            {localization.Titles.bundle[language]}
          </Typography>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {bundles?.map((item) => {
                if (item.is_visible && item.total_available_quantity > 0) {
                  const matchingPrice = item.prices.find(
                    (price) => price.currency === currency
                  );
                  if (matchingPrice) {
                    return (
                      <Grid
                        item
                        xs={12}
                        lg={6}
                        key={item.id}
                        style={{
                          width: "auto",
                          flexShrink: "100 !important",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: colors.background.pureWhite,
                            borderRadius: "20px",
                            border:
                              ticket === item.id
                                ? `2px solid ${colors.background.secondary}`
                                : `1px solid ${colors.background.outline}`,
                            transition: "background-color 0.3s",
                            backgroundColor:
                              ticket === item.id
                                ? colors.background.secondary
                                : "",
                            "&:hover": {
                              backgroundColor: colors.background.secondary,
                              borderRadius: "20px",
                              cursor: "pointer",
                            },
                          }}
                          padding={1}
                          width="100%"
                          border="1px solid white"
                          onClick={() => {
                            setPrice(matchingPrice.price);
                            setCurrency(matchingPrice.currency);
                            setBundle(true);
                            setTicket(item.id);
                          }}
                        >
                          <Grid
                            container
                            justifyContent={"center"}
                            justifyItems="center"
                            alignContent={"center"}
                            alignItems="center"
                          >
                            <Grid item xs={9}>
                              <FeaturedTitle
                                sx={{
                                  color: colors.background.tertiary,
                                  fontWeight: "300",
                                  fontSize: "30px",
                                }}
                              >
                                {roundToTwoDecimalPlaces(matchingPrice.price) +
                                  matchingPrice.currency}
                              </FeaturedTitle>
                              <Typography
                                fontFamily={"Comfortaa"}
                                fontWeight={300}
                                fontSize="14px"
                                sx={{
                                  color: colors.background.tertiary,
                                }}
                              >
                                {item.name[language]}
                              </Typography>
                            </Grid>
                            <Grid
                              justifyContent={"center"}
                              justifyItems="center"
                              alignContent={"center"}
                              alignItems="center"
                              item
                              xs={3}
                            >
                              <IconButton
                                aria-label="filter"
                                sx={{
                                  marginRight: 2,
                                  backgroundColor: colors.background.primary,
                                  color: colors.background.tertiary,
                                  "&:hover": {
                                    backgroundColor: colors.background.tertiary,
                                    color: colors.background.primary,
                                  },
                                  ml: 1,
                                }}
                              >
                                <AiOutlineShoppingCart
                                  sx={{
                                    height: 32,
                                    width: 32,
                                  }}
                                />
                              </IconButton>
                            </Grid>

                            {visible && (
                              <Grid
                                justifyContent={"center"}
                                justifyItems="center"
                                alignContent={"center"}
                                alignItems="center"
                                item
                                xs={12}
                              >
                                {item?.rules?.map((rule) => {
                                  return (
                                    <Typography
                                      fontFamily={"Comfortaa"}
                                      fontWeight={500}
                                      fontSize="14px"
                                      sx={{
                                        color: colors.background.tertiary,
                                      }}
                                      textAlign={"center"}
                                    >
                                      {rule?.included_quantity +
                                        "x " +
                                        rule?.ticket?.name[language]}
                                    </Typography>
                                  );
                                })}
                              </Grid>
                            )}
                            {visible ? (
                              <KeyboardDoubleArrowUp
                                onClick={() => setVisible(false)}
                                sx={{
                                  color: colors.background.tertiary,
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <KeyboardDoubleArrowDown
                                onClick={() => setVisible(true)}
                                sx={{
                                  color: colors.background.tertiary,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  }
                }
                return null;
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default PriceSelect;

