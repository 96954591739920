import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import ReactCrop, { makeAspectCrop, centerCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ModalItemsContainer } from "components/CustomModal/Styles";
import { useModal } from "contexts/modalContext";
import { IMAGE_UPLOAD_SIZE } from "constants/sizes";

const ImageCrop = ({
  src,
  isCropOn = false,
  onChange,
  aspectRatio = 16 / 9,
}) => {
  const [sizeErr, setSizeErr]= useState(false);

  const { toggleModal } = useModal();
  const [crop, setCrop] = useState({
    unit: "px",
    height: 100,
    aspect: aspectRatio,
  });
  const [image, setImage] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);

  useEffect(() => {
    setOriginalFile(src.file);
  }, []);

  const onImageLoad = (e) => {
    const img = e.currentTarget;
    setImage(img);
    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "px",
          width: img.width * 0.5,
        },
        aspectRatio,
        img.width,
        img.height
      ),
      img.width,
      img.height
    );

    setCrop(crop);
  };

  const getCroppedImage = (fileType) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL(fileType);
    return base64Image;
  };

  return (
    <Modal
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        overflowY: "hidden",
        zIndex: 10000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={isCropOn}
      onClose={() => toggleModal("modal1")}
    >
      <ModalItemsContainer
        sx={{ background: "black", width: "90vw", height: "90vw" }}
      >
        <Box
          sx={{
            px: 2,
            pt: 1,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() => toggleModal("modal1")}
            sx={{
              alignSelf: "flex-end",
              position: "absolute",

              top: "0",
              right: "0",
              mb: 8,
            }}
          >
            <MdClose     onClick={() => toggleModal("modal1")} color="white" />
          </IconButton>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              aspect={aspectRatio}
              style={{
                maxHeight: "60dvh",
                width: { xs: "100%", md: "80%" },
              }}
            >
              <img src={src.data_url} alt="crop" onLoad={onImageLoad} />
            </ReactCrop>
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 2, width: "80%" }}
            onClick={async () => {
              const fileType = originalFile.type;
              const fileName = originalFile.name;
              const dataUrl = getCroppedImage(fileType);
              const file = await fetch(dataUrl)
                .then((res) => res.blob())
                .then((blob) => {
                  return new File([blob], fileName, {
                    type: fileType,
                  });
                });
                console.log(file.size)
             if(file.size> IMAGE_UPLOAD_SIZE){
              
              setSizeErr(true)
             }else
              {
                setSizeErr(false)
                onChange([{ data_url: dataUrl, file }]);}
            }}
          >
            Next
          </Button>
          {sizeErr && (
                <>
                
                    <Typography mt={3} color="error" variant="p2">
                     The Image Size has exceeded the limit after being cropped. Please provide an image with a Smaller Size or crop it in a smaller scale.
                    </Typography>
              
                  
                </>
              )}
        </Box>
      </ModalItemsContainer>
    </Modal>
  );
};

export default ImageCrop;