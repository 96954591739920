import { Box, Grid, /*Typography,  Pagination*/ } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
//import SquareEventCard from "components/Cards/SquareEventCard";
import SquareLoadingEventCard from "components/Cards/SquareEventCard/SquareLoadingEventCard";
import useGetData from "hooks/useGetData";
//import {useGetEventsQuery, useGetLiveEventsQuery,} from "features/events/eventsApiSlice";
import defaultAd from "../../assets/images/ad300x300.jpg";
import defaultAd2 from "../../assets/images/ad300x400.jpg";
import defaultAd3 from "../../assets/images/ad500x100.jpg";
//import defaultAd4 from "../../assets/images/ad800x100.jpg";

import SnippitHeader from "pages/Homepage/Snippit";
//import { colors } from "constants/themeConstants";
//import { Ads } from "components/Ads";
//import CategoryEvents from "pages/Homepage/CategoryEvents";
import { useGetAdsQuery } from "features/ads/adApiSlice";
import { apiUrl } from "constants/api";
import {
  StyledAdCircular,
  StyledAdRounded,
  /*StyledAdSharp,*/
} from "pages/Homepage/Styles";
import { useGetSettingsQuery } from "features/settings";
//import EventsCategoriesHeader from "pages/Events/EventsCategoriesHeader";
//import FeaturedEvents from "pages/home/Home/FeaturedEvents";
import SquareServiceCard from "components/Cards/SquareEventCard/variant";
import Service from "pages/Homepage/Services";
import BodyWrapper from "components/BodyWrapper";
import ResponsiveAppBar from "pages/Homepage/appBar";
import {
  /*useGetAllServicesQuery,
  useGetServiceTypeQuery,*/
  useGetServiceProvidersQuery,
} from "features/services/servicesApiSlice";
//import useGetDataPagination from "hooks/useGetDataPagination";
import { localization } from "constants/localalization";
import { LanguageContext } from "hooks/useLanguage";
import ServicesHeader from "./ServicesHeader";
import { useErrorBoundary } from "react-error-boundary";
const Services = ({
  query,
  category,
  filterValues,
  title = "Owl the Events",
}) => {
  const servicesList = [
    // { title: "Venues" },
    // { title: "DJs" },
    // { title: "Chefs" },
    // { title: "Catering" },
    // { title: "Mixologist" },
    // { title: "Decorators" },
  ];
  // const [pagination] = usePagination();
  // const { data: eventsRaw, isLoading: isEventsLoading } = useGetEventsQuery({
  //   pagination,
  // });
  const {
    isLoading,
    setPagination,
    setFilter,
    data: services,
    setShowFilter,
    isError,
    error,
    /*pagination,
    rowCount,
    searchValue,
    setSearchValue,
    showSearch,
    setLiteralFilter,
    setShowSearch,
    refetch,*/
  } = useGetData(true)(useGetServiceProvidersQuery);
  const data = services.data;
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
     
    if (isError) {
      showBoundary(error);
    }
  
   
  
  }, [isError, error, showBoundary]);
  // const events = eventsRaw ? eventsRaw.data : [];
  const loadingCards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [currentCat, setCurrentCat] = useState([{ name: "All", id: 0 }]);
  const [catChange, setCatChange] = useState(0);
  const { language } = useContext(LanguageContext);

  // var cat= category;
  useEffect(() => {
    console.log("currentCat", currentCat);
    setPagination({ pageIndex: 0, pageSize: 12 });
    // if (query !== "") {
    //   setShowSearch(true);
    //   setSearchValue(query);
    // }

    if (currentCat[0]?.id !== 0) {
      setShowFilter(true);
      const filter = currentCat.map((element) => ({
        key: element.id,
        category_id: element.id,
      }));
      setFilter(filter);
    }
    // if (filterValues || filterValues === "status=ONGOING") {
    //   setCurrentCat([{ name: "All", id: 0 }]);
    //   setLiteralFilter(filterValues);
    // }
  }, [query, filterValues, catChange]);
  const { data: adleftone, isLoading: isAdsLoading1 } = useGetAdsQuery({
    id: "EVENT_PAGE_LEFT_FIRST",
  });
  const { data: adlefttwo, isLoading: isAdsLoading2 } = useGetAdsQuery({
    id: "EVENT_PAGE_LEFT_SECOND",
  });
  const { data: adrightone, isLoading: isAdsLoading3 } = useGetAdsQuery({
    id: "EVENT_PAGE_RIGHT_FIRST",
  });
  const { data: adrighttwo, isLoading: isAdsLoading4 } = useGetAdsQuery({
    id: "EVENT_PAGE_RIGHT_SECOND",
  });
  const { data: logo, isLoading: isLoadingLogo } = useGetSettingsQuery();
  return (
    <BodyWrapper>
      <ResponsiveAppBar />
      <Box height={"auto"} sx={{ pb: 4, px: { xs: 2, md: 4 } }}>
        <Grid container>
          <Grid px={4} paddingLeft={1} item xs={12} md={4} lg={4} xl={3}>
            <img
              alt="img"
              className='mx-[auto] md:mt-5 md:mb-5'
              width={"80rem"}
              src={`${apiUrl}${logo?.logo.image_large_path}`}
              sx={{ display: { xs: "none", md: "flex" } }}
            />
            <ServicesHeader
              currentCat={currentCat}
              catChange={catChange}
              setCatChange={setCatChange}
              setCurrentCat={setCurrentCat}
            />
            <Grid
              xs={12}
              sm={6}
              md={12}
              marginX={"auto"}
              // className="flex justify-center align-center"
            >
              {/* <FeaturedEvents
                color={colors.background.golden}
                isLoading={isLoading}
                events={data}
              /> */}
            </Grid>

            <Box
              width={"100%"}
              px={{ xs: 0, md: 2 }}
              className='sm:max-md:flex sm:max-md:flex-row sm:max-md:gap-2'
            >
              {adleftone?.length > 0 ? (
                <StyledAdRounded src={`${apiUrl}${adleftone[0]?.image}`} />
              ) : (
                <StyledAdRounded
                  src={defaultAd}
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                />
              )}
              {adlefttwo?.length > 0 ? (
                <StyledAdRounded src={`${apiUrl}${adlefttwo[0]?.image}`} />
              ) : (
                <StyledAdRounded
                  src={defaultAd2}
                  sx={{
                    objectFit: "fill",
                    height: "auto",
                    width: "100%",
                  }}
                />
              )}
            </Box>
          </Grid>
          <Grid
            mt={3}
            height={"auto"}
            pl={{ xs: 2, lg: 3 }}
            pr={{ xs: 2 }}
            item
            xs={12}
            md={8}
            xl={9}
          >
            {currentCat[0].id === 0 && (isLoading || data?.length > 0) && (
              <>
                <SnippitHeader
                  title={localization.EventsListNew["Here are"][language]}
                  title2={
                    " Owl the Services"
                    // " " + localization.EventsListNew["Owl the Events"][language]
                  }
                />
                <Grid
                  className='h-[auto]'
                  container
                  spacing={2}
                  sx={{ mt: 2, pr: { xs: 0, md: 4 } }}
                >
                  {isLoading ? (
                    loadingCards.map((item) => (
                      <Grid
                        key={item}
                        item
                        xs={12}
                        sm={6}
                        md={12}
                        lg={6}
                        xl={6}
                      >
                        <SquareLoadingEventCard />
                      </Grid>
                    ))
                  ) : (
                    <>
                      {data.map((item, index) =>
                        index === 4 ? (
                          <>
                            <Grid item xs={12} key={item.id}>
                              {adrightone?.length > 0 ? (
                                <StyledAdRounded
                                  onClick={() =>
                                    window.open(adrightone[0]?.link)
                                  }
                                  src={`${apiUrl}${adrightone[0]?.image}`}
                                  sx={{
                                    objectFit: "fill",
                                    height: "auto",
                                    width: "100%",
                                  }}
                                />
                              ) : (
                                <StyledAdRounded
                                  src={defaultAd3}
                                  onClick={() =>
                                    window.open("https://web.awuraplc.org/")
                                  }
                                  sx={{
                                    objectFit: "fill",
                                    height: "auto",
                                    width: "100%",
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid
                              // key={event.id}
                              item
                              xs={12}
                              sm={6}
                              md={12}
                              lg={6}
                              xl={6}
                            >
                              <SquareServiceCard event={item} />
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            // key={event.name}
                            item
                            xs={12}
                            sm={6}
                            md={12}
                            lg={6}
                            xl={6}
                          >
                            <SquareServiceCard event={item} />
                          </Grid>
                        )
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
            {currentCat[0].id !== 0 && (isLoading || data?.length > 0) && (
              <Grid
                // className="sm:h-[800px] md:h-[800px] lg:h-[600px] xl:h-[auto]"
                container
                spacing={2}
                sx={{ pr: { xs: 0, md: 0 } }}
              >
                {currentCat?.map((item, index) => (
                  <React.Fragment key={index}>
                    {index % 2 === 0 && index > 1 && (
                      <Box width={"100%"} mx={{ xs: 2, md: 8 }} mb={4}>
                        {adrightone?.length > 0 ? (
                          <StyledAdCircular
                            src={`${apiUrl}${adrightone[0]?.image}`}
                          />
                        ) : (
                          <StyledAdCircular
                            src={defaultAd3}
                            sx={{
                              objectFit: "fill",
                              height: "auto",
                              width: "100%",
                            }}
                          />
                        )}
                      </Box>
                    )}
                    <Service
                      title='Zoom Through '
                      title2={item.name}
                      category={item.id}
                      seeAll={"/events"}
                    />
                  </React.Fragment>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </BodyWrapper>
  );
};

export default Services;
