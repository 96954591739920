import { useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { Avatar, Box, InputLabel, Typography } from "@mui/material";
import { useField } from "formik";
import DefaultImage from "assets/images/default_image.png";
import DisplayImage from "./DisplayImage";
import { IMAGE_UPLOAD_SIZE } from "constants/sizes";
import ImageCrop from "./ImageCrop";

const FormikImagePicker = (props) => {
  const {
    initialImage = "",
    name,
    label,
    placeholder = null,
    width = "200px",
    height = "200px",
    required = false,
    disabled = false,
    acceptType = [],
    maxFileSize = IMAGE_UPLOAD_SIZE,
    enableCrop = false,
    aspectRatio = null,
  } = props;

  const [images, setImages] = useState([]);
  const [isCropOn, setIsCropOn] = useState(false);

  const maxNumber = 10;
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  useEffect(() => {
    if (typeof field.value === "object") {
      const reader = new FileReader();
      reader.readAsDataURL(field.value);
      reader.onload = () => {
        setImages([{ data_url: reader.result }]);
      };
    } else {
      setImages([{ data_url: initialImage }]);
    }
  }, [field.value]);

  const onChange = (imageList) => {
    if (
      imageList.length > 0
      // && imageList[0].file.size < IMAGE_UPLOAD_SIZE
    ) {
      setImages(imageList);
      setValue(imageList[0].file);

    }
    if (enableCrop) setIsCropOn((prev) => !prev);
  };

  if (disabled) {
    return (
      <DisplayImage
        width={width}
        height={height}
        src={
          initialImage === null || initialImage === ""
            ? placeholder || DefaultImage
            : initialImage
        }
      />
    );
  }

  if (isCropOn) {
    return (
      <ImageCrop
        src={images[0]}
        isCropOn={isCropOn}
        onChange={onChange}
        aspectRatio={aspectRatio}
      />
    );
  }

  return (
    <ImageUploading
      multiple={false}
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
      acceptType={acceptType}
      maxFileSize={maxFileSize}
    >
      {({ imageList, onImageUpdate, errors }) => (
        // write your building UI
        <Box sx={{ my: 1, height: "auto" }}>
          {label && (
            <InputLabel htmlFor={name}>
              {label}
              {required && " *"}
            </InputLabel>
          )}
          <Box display="flex" flexDirection="column">
            {imageList.map((image, index) => (
              <Box
                key={index}
                id={name}
                onClick={() => onImageUpdate(index)}
                sx={{ cursor: "pointer", width, height }}
              >
                {image["data_url"] === "" ? (
                  <Avatar
                    sx={{ width, height, borderRadius: "0px" }}
                    src={placeholder || DefaultImage}
                  />
                ) : (
                  <>
                    <img
                      src={image["data_url"]}
                      alt=""
                      width={width}
                      height={height}
                    />
                  </>
                )}
              </Box>
            ))}
            <Box>
              {meta.touched && Boolean(meta.error) ? (
                <Typography color="error" variant="p2">
                  {meta.error}
                </Typography>
              ) : (
                <></>
              )}
              {errors && (
                <>
                  {errors.maxNumber && (
                    <Typography color="error" variant="p2">
                      Number of selected images exceed maxNumber
                    </Typography>
                  )}
                  {errors.acceptType && (
                    <Typography color="error" variant="p2">
                      Your selected file type is not allowed, only{" "}
                      {acceptType.map((item) => `${item}, `)} files allowed.
                    </Typography>
                  )}
                  {errors.maxFileSize && (
                    <Typography color="error" variant="p2">
                      {`Max allowed file size is ${
                        IMAGE_UPLOAD_SIZE / 1024
                      }KB.`}
                    </Typography>
                  )}
                  {errors.resolution && (
                    <Typography color="error" variant="p2">
                      Selected file does not match your desired resolution
                    </Typography>
                  )}
                </>
              )}
              
            </Box>
          </Box>
        </Box>
      )}
    </ImageUploading>
  );
};

export default FormikImagePicker;