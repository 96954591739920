import {
  Box,
  Grid,
  Skeleton,
  Typography,
  /*Button,
  IconButton,
  Radio,*/
} from "@mui/material";
import BodyWrapper from "components/BodyWrapper";
import React, { useContext, useEffect, useState } from "react";
//import LocationOnIcon from "@mui/icons-material/LocationOn";
//import CategoryIcon from "@mui/icons-material/Category";
//import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
//import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { apiUrl, /*vendorEventApply*/ } from "constants/api";
//import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
//import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  //useGetEventQuery,
  useGetEventsQuery,
  //useGetSimilarEventsQuery,
} from "features/events/eventsApiSlice";
//import { IoIosNotifications, IoMdTime } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";
//import { getParsedDate, getParsedTime } from "utils/dateFormat";
//import StorefrontIcon from "@mui/icons-material/Storefront";
import { differenceInDays } from "date-fns";
//import {FeaturedBody, FeaturedTitle,} from "components/Cards/FeaturedEventCard/Styles";
//import { IoPricetagOutline } from "react-icons/io5";
//import { FaRegBuilding } from "react-icons/fa";
//import { Swiper, SwiperSlide } from "swiper/react";
//import { Navigation } from "swiper";
import { colors } from "../../constants/themeConstants";
import { useGetVendorsQuery } from "features/vendor/vendorApiSlice";
//import SearchBar from "components/SearchBar";
import ResponsiveAppBar from "pages/Homepage/appBar";
import {
  MusicNoteOutlined,
  /*ShoppingBagRounded,
  Title,*/
} from "@mui/icons-material";
//import { AiOutlineShoppingCart } from "react-icons/ai";
//import { Ads } from "components/Ads";
//import MoreEvents from "pages/Homepage/MoreEvents";
//import {StyledCategoryChip, StyledDateTextBody,} from "../../components/Cards/SquareEventCard/Styles";
import {
  StyledAdSharp,
  /*StyledAd,
  StyledAdRounded,
  StyledTitleYellow,*/
} from "pages/Homepage/Styles";
import { useGetAdsQuery } from "features/ads/adApiSlice";
import { useGetSettingsQuery } from "features/settings";
//import EventsCategoriesHeader from "pages/Events/EventsCategoriesHeader";
// import {
//   StyledCategoryChipDetail,
//   StyledInfoMore,
//   StyledLinearProgress,
//   StyledTitle,
// } from "./styles";
import defaultAd from "../../assets/images/ad800x100.jpg";
import { LanguageContext } from "hooks/useLanguage";
//import EventCategoryUI from "pages/Events/EventCategoryUI";
//import useGetData from "hooks/useGetData";
import useGetDataPagination from "hooks/useGetDataPagination";
import { localization } from "constants/localalization";
import {
  useGetServiceProviderQuery,
  useGetServiceProviderRequestFormQuery,
  //useGetServiceQuery,
  useGetServicesbyProviderQuery,
  useGetSimilarServiceProvidersQuery,
} from "features/services/servicesApiSlice";
import {
  StyledInfoMore,
  //StyledLinearProgress,
  StyledTitle,
} from "pages/EventDetail/styles";
import CheckoutModal from "components/CheckoutService/CheckoutModal";
import { StyledButton, StyledService } from "./styles";
import { BsArrowUpRight } from "react-icons/bs";
//import SquareServiceCard from "components/Cards/SquareEventCard/variant";
//import SquareLoadingEventCard from "components/Cards/SquareEventCard/SquareLoadingEventCard";
import MoreServices from "pages/Homepage/MoreServices";

const DescListItem = (props) => {
  const { title, desc } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "left", lg: "center" },
        py: 1,
      }}
    >
      <Typography
        color={colors.background.secondary}
        fontWeight={400}
        fontSize='14px'
        fontFamily={"Comfortaa"}
        // sx={{ ml: 1.5 }}
      >
        {title}
      </Typography>
      <Typography
        color={colors.background.tertiary}
        fontWeight={400}
        fontSize='14px'
        fontFamily={"Comfortaa"}
        sx={{ ml: 1.5 }}
      >
        {desc}
      </Typography>
    </Box>
  );
};

const Recommended = () => {
  const { data: eventsRaw, isLoading: isEventsLoading } = useGetEventsQuery();
  const events = eventsRaw ? eventsRaw.data : [];

  return (
    <>
      <Typography variant='h5' fontWeight={400} color='textPrimary'>
        Recommended
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
        }}
      >
        {events.map((event) => (
          <Grid key={event.id} item xs={4} md={2}>
            <img
              alt="img"
              src={`${apiUrl}${event?.image?.image_small_path}`}
              style={{ objectFit: "cover", height: "148px", width: "100%" }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const EventDate = ({ event }) => {
  const start = differenceInDays(new Date(event.start_date), new Date());
  const end = differenceInDays(new Date(event.end_date), new Date());
  return (
    <Typography
      sx={{ color: colors.background.tertiary }}
      marginBottom={"25px"}
      textAlign={"right"}
      fontSize={{ xs: "26px", md: "32px" }}
      textTransform={"uppercase"}
      fontFamily={"Comfortaa"}
      fontWeight={400}
    >
      {start < 0 && end < 0 ? (
        `PAST`
      ) : start < 0 && end >= 0 ? (
        `ONGOING`
      ) : (
        <>
          {Math.abs(start)} {Math.abs(start) > 1 ? "Days left" : "Day left"}
        </>
      )}
    </Typography>
  );
};

const ServiceDetail = (props) => {
  const { language } = useContext(LanguageContext);
  const [searchParams] = useSearchParams();
  const event_id = searchParams?.get("service_id");
  const { data: event, isLoading } = useGetServiceProviderQuery({
    id: event_id,
  });

  const { data: services, isLoading: isLoadingServices } =
    useGetServicesbyProviderQuery({
      id: event_id,
    });
  console.log("event", event);
  console.log("service", services);

  const { data: vendor, isLoadingVendor } = useGetVendorsQuery({
    id: event_id,
  });
  // const { data: similar, isLoading: isSimilarLoading } =
  //   useGetSimilarEventsQuery({ id: event_id });
  const {
    isLoading: isSimilarLoading,
    pagination,
    setPagination,
    rowCount,
    data: similar,
    setEventId,
    refetch,
  } = useGetDataPagination()(useGetSimilarServiceProvidersQuery);
  const { data, isSuccess, isLoading:load,  } =useGetServiceProviderRequestFormQuery({ id: event_id });

  // console.log('data',data)
  console.log("similar", similar);

  const { data: ads, isLoading: isAdsLoading } = useGetAdsQuery({
    id: "DETAIL_PAGE",
  });

  const [modalData, setModalData] = useState({
    open: false,
    id: 0,
    price: 0,
    name: "",
  });
  const [selectedValue, setSelectedValue] = React.useState();
  const [priceValue, setpriceValue] = React.useState();
  const { data: logo, isLoading: isLoadingLogo } = useGetSettingsQuery();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [isTruncated, setIsTruncated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [truncatedValue, setTruncatedValue] = useState();
  const info = event?.description
    ? event?.description[language]
    : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ";
  const navigate = useNavigate();
  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 6 });
    setEventId(event_id);

    if (info?.length > 300) {
      setIsTruncated(true);
      setTruncatedValue(info?.substring(0, 300));
    }
  }, [info]);
  const loadingCards = [1, 2, 3, 4];
  const moreServices = [];
  if (!isLoading) {
    for (let index = 0; index < 6; index++) {
      moreServices.push(event);
    }
  }

  return (
    <BodyWrapper>
      {isLoading ? (
        <Box>
          <Skeleton variant='rectangular' width='100%' height='320px' />
          <Box height='80vh'>
            {" "}
            <Skeleton variant='text' width='40%' sx={{ mt: 6 }} />
            <Grid container>
              <Grid item xs={6}>
                <Skeleton variant='text' width='20%' />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
                <Skeleton variant='text' width='20%' />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
                <Skeleton variant='text' width='20%' />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
                <Skeleton variant='text' width='20%' />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
              </Grid>
              <Skeleton variant='rectangular' width='100%' height='500px' />
              <Grid item xs={6}>
                <Skeleton variant='text' width='20%' />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
                <Skeleton variant='text' width='40%' sx={{ mt: 2 }} />
              </Grid>
              <Skeleton variant='rectangular' width='100%' height='500px' />
            </Grid>
          </Box>
        </Box>
      ) : (
        <>
          <ResponsiveAppBar />
          <Box>
            <Box
              sx={{
                mt: 3,
                mb: 7,
                mx: "auto",
                display: "flex",
                position: "relative",
                justifyContent: "center",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              
            </Box>
            <Box
              sx={{
                // backgroundImage:`${apiUrl}${event?.image?.image_large_path}`,
                // width: "400px",
                maxHeight: "540px",
                // overflow: "hidden",
                position: "relative",
                display: "flex", // Set display to flex
                justifyContent: "center", // Align horizontally to center
                alignItems: "center", // Ali
              }}
            >
              <img
                alt="img"
                src={`${apiUrl}${event?.company?.logo?.image_large_path}`}
                style={{
                  objectFit: "cover",
                  width: { xs: "760px", md: "960px" },
                  height: "540px",
                  justifySelf: "center",
                  alignSelf: "center",
                  opacity: 1,
                  zIndex: 1,
                  display: "flex",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 0, // Place the background below the image
                  "::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${apiUrl}${event?.company?.logo?.image_large_path})`,
                    backgroundSize: "cover",
                    // filter: "blur(8px)", // Apply the blur effect to the background
                    opacity: 0.3, // Adjust the opacity as desired
                  },
                }}
              />
              <Box
                style={{
                  position: "absolute",
                  bottom: -10,
                  left: 65,
                  zIndex: 2,
                }}
              >
                <Grid gap={3} container>
         
                </Grid>
              </Box>
            </Box>
          </Box>
          <Grid container>
            <Grid item px={{ xs: 4, md: 8 }} mt={8} lg={6}>
              <Box
                onClick={() => {
                  navigate(`/organizers/detail?org_id=${event?.company?.id}`);
                }}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Typography
                  sx={{
                    color: colors.background.secondary,
                  }}
                  fontFamily={"Comfortaa"}
                  fontWeight={300}
                  fontSize='1.3rem'
                >
                  OwlEvents
                </Typography>
                <Typography
                  sx={{
                    color: colors.background.tertiary,
                    ml: 1,
                  }}
                  fontFamily={"Comfortaa"}
                  fontWeight={300}
                  fontSize='1.3rem'
                >
                  {localization.Labels.PresentsYou[language]}
                </Typography>
              </Box>

              <Typography
                marginBottom={3}
                fontFamily={"Comfortaa"}
                fontSize={{ xs: "26px", md: "42px" }}
                fontWeight={300}
                color={colors.background.tertiary}
              >
                {event?.company?.name[language]}
              </Typography>
              <Typography
                marginBottom={3}
                fontWeight={300}
                fontSize={"1.1rem"}
                fontFamily={"Comfortaa"}
                textAlign={"left"}
                color={colors.background.tertiary}
              >
                {isTruncated ? truncatedValue : info}
                {(isTruncated || isExpanded) && (
                  <StyledInfoMore
                    style={{
                      color: colors.background.secondary,

                      display: "inline",
                    }}
                    onClick={() => {
                      setIsTruncated(!isTruncated);
                      setIsExpanded(!isExpanded);
                    }}
                  >
                    {isExpanded ? " less" : " more"}
                  </StyledInfoMore>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              justifyContent={"end"}
              justifyItems='end'
              px={{ xs: 6, md: 15 }}
              mt={{ xs: 4, md: 10 }}
              lg={6}
            >
              {/* <EventDate event={event} /> */}
              <Grid container width={"100%"}>
                {services?.length > 0 && (
                  <Grid
                    container
                    justifyContent='start'
                    alignItems='start'
                    spacing={0}
                  >
                    <MusicNoteOutlined />
                    <Typography
                      fontFamily={"Comfortaa"}
                      fontWeight={"300"}
                      ml={1}
                      fontSize={"1.3rem"}
                    >
                      Which services are you looking for?
                    </Typography>
                  </Grid>
                )}

                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={0}
                >
                  <Grid item xs={12}>
                    {services?.length === 0 && (
                      <StyledTitle>No Available Services</StyledTitle>
                    )}

                    <Grid container>
                      {services?.map((item) => {
                        return (
                          <>
                            <>
                              <Grid
                                item
                                xs={6}
                                mt={3}
                                key={item.id}
                                style={{
                                  width: "auto",
                                  flexShrink: "100 !important",
                                }}
                              >
                                <StyledService>
                                  <Grid
                                    container
                                    alignContent={"center"}
                                    alignItems={"center"}
                                    alignSelf={"center"}
                                    justifyContent={"center"}
                                    justifyItems={"center"}
                                    justifySelf={"center"}
                                  >
                                    <Grid xs={9} alignContent={"center"} item>
                                      <Typography
                                        textAlign={"center"}
                                        fontFamily={"Comfortaa"}
                                        fontWeight={"400"}
                                        // ml={1}
                                        fontSize={"0.8rem"}
                                      >
                                        {item?.name}
                                      </Typography>
                                    </Grid>
                                    <Grid xs={3} item>
                                      <BsArrowUpRight
                                        style={{
                                          background: colors.background.primary,
                                          padding: "8px",
                                          borderRadius: "30px",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        color={colors.background.tertiary}
                                      />
                                    </Grid>
                                  </Grid>
                                </StyledService>
                                
                              </Grid>
                            </>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          
          </Grid>
          <Box
            sx={{
              mt: 3,
              mb: 7,
              mx: "auto",
              display: "flex",
              position: "relative",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <StyledButton
              onClick={() =>
                setModalData((prev) => ({
                  ...prev,
                  open: true,
                  id: 0,
                  eventId: event_id,
                  data: data,
                  desc: "item.desc",
                  name: event?.company?.name,
                  price: 10,
                }))
              }
            >
              Book
            </StyledButton>
          </Box>
          <Box width={"100%"} px={8}>
            {ads?.length > 0 ? (
              <StyledAdSharp
                onClick={() => window.open(ads[0]?.link)}
                src={`${apiUrl}${ads[0]?.image}`}
              />
            ) : (
              <StyledAdSharp
                onClick={() => window.open("https://web.awuraplc.org/")}
                src={defaultAd}
                sx={{
                  objectFit: "fill",
                  height: "auto",
                  width: "100%",
                }}
              />
            )}
          </Box>
          <img
            alt="logo"
            className='mx-[auto]'
            // onClick={() => navigate("/")}
            width={"160px"}
            src={`${apiUrl}${logo?.logo?.image_small_path}`}
           
            sx={{ display: { xs: "none", md: "flex" } }}
          />
         
          {similar?.length > 0 && (
            <MoreServices
              title={localization.Titles.checkMore[language]}
              title2={" " + event?.service_type?.name +'s'}
              events={similar}
              pagination={pagination}
              refetch={refetch}
              rowCount={rowCount}
              setPagination={setPagination}
              isLoading={isSimilarLoading}
              seeAll={"/events"}
            />
          )}
        
        </>
      )}
      <CheckoutModal modalData={modalData} setModalData={setModalData} />
    </BodyWrapper>
  );
};

export default ServiceDetail;
