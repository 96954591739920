//import { useMemo } from "react";
import { Grid } from "@mui/material";
import {
  FastCheckboxField,
  //FastDateField,
  FastDateTimeField,
  FastPhoneField,
  FastSelectField,
  FastSelectFieldSingle,
  FastSwitchField,
  //FastSwitchField,
  FastTextAreaField,
  FastTextField,
  FastTimeField,
  FileUploadField,
  ImageUploadField,
  ReactHookFormSelect,
} from "../CustomReactHookFields";
//import { Typography } from "@mui/material";
import {
  useGetAccessoriesQuery,
  useGetServicesbyProviderQuery,
} from "features/services/servicesApiSlice";

export const GetFields = ({ fields, disabled = false, control, id }) => {
  // const sorted_fields = useMemo(
  //   () =>
  //     fields.length > 0 && fields[0]?.position
  //       ? [...fields].sort((a, b) => {
  //           return a.position > b.position ? 1 : -1;
  //         })
  //       : fields,
  //   [fields]
  // );
  const { data: services, isLoading: isLoadingServices } =
    useGetServicesbyProviderQuery({
      id: id,
    });
  const { data: accessories, isLoading: isLoadingAccessories } =
    useGetAccessoriesQuery({
      id: id,
    });
  const sortedFields = [...fields].sort((a, b) => a.sorting - b.sorting);

  return (
    <>
      {/* {Object.keys(fields).map((fieldName, index) => {
  const item = fields[fieldName];
  const commonProps = {
    key: fieldName,
    label: item.label || fieldName,
    name: fieldName,
    disabled,
    control,
  };
        switch (item.type) {
          case "Text":
            {
              if(fieldName=='accessories'){
                return <FastCheckboxField options={accessories || []} {...commonProps} />;

              }else
              return <FastTextField {...commonProps} type="text" />;

            }
          case "Number":
          
              return <FastTextField {...commonProps} type="number" />;
            
          case "Email":
            return <FastTextField {...commonProps} type="email" />;
          case "SelectMultiple":
            // return <>ddd</>;

            return <FastSelectField options={accessories || []} {...commonProps} />;
          case "TimeField":
            return <FastTimeField {...commonProps} />;
          case "DateTimeField":
            return <FastDateTimeField {...commonProps} />;
          case "IntegerField":
            return <FastTextField {...commonProps} type="number" />;
          case "DecimalField":
            return <FastTextField {...commonProps} type="number" />;
          case "ShortAnswerField":
            return <FastTextField {...commonProps} />;
          case "ParagraphField":
            return <FastTextAreaField {...commonProps} />;
          default:
            return <>ddd</>;
        }
      })} */}

      {sortedFields?.map((item, index) => {
        const commonProps = {
          key: index,
          label: item.question || item.name,
          name: item.id.toString(),
          disabled,
          control,
          required: item?.is_required,
        };
        switch (item?.question_type) {
          case "PARAGRAPH_TEXT":
            return (
              <Grid item xs={10} lg={6}>
                <FastTextField
                  label={item?.question}
                  {...commonProps}
                  type="text"
                />
              </Grid>
            );
          case "EMAIL":
            return (
              <Grid item xs={10} lg={6}>
                <FastTextField
                  label={item?.question}
                  {...commonProps}
                  type="email"
                />
              </Grid>
            );
          case "PHONE":
            return (
              <Grid item xs={10} lg={6}>
                <FastPhoneField label={item?.question} {...commonProps} />
              </Grid>
            );
          case "MULTIPLE_CHOICE":
            return (
              <Grid item xs={10} lg={6}>
                <FastSelectField
                  options={item?.choices}
                  control={control}
                  required={item?.is_required}
                  name={item.id.toString()}
                  label={item.question}
                ></FastSelectField>
              </Grid>
            );
          case "SINGLE_CHOICE":
            return (
              <Grid item xs={10} lg={6}>
                {" "}
                <FastSelectFieldSingle
                  options={item?.choices}
                  required={item?.is_required}
                  name={item.id.toString()}
                  {...commonProps}
                />
              </Grid>
            );
          case "LINK":
            return (
              <Grid item xs={10} lg={6}>
                <FastTextField
                  type={"url"}
                  required={item?.is_required}
                  label={item.question}
                  name={item.id.toString()}
                  control={control}
                />
              </Grid>
            );
          case "IMAGE_BASE64_HEX":
            return (
              <Grid item xs={10} lg={6}>
                <ImageUploadField
                  required={item?.is_required}
                  label={item.question}
                  name={item.id.toString()}
                  control={control}
                />
              </Grid>
            );

          // case "IMAGE_BASE6_HEX'":
          //   return (
          //     <Grid item xs={12} lg={6}>
          //       <Grid item xs={12} lg={6}>
          //         <ImageUploadField
          //           required={item?.is_required}
          //           label={item.question}
          //           name={item.id.toString()}
          //           control={control}
          //         />
          //       </Grid>
          //     </Grid>
          //   );
          case "ADDTIONAL_TERMS_AND_CONDTIONS":
            return (
              <Grid item xs={12} lg={12}>
                <FastCheckboxField
                  required={item?.is_required}
                  options={[item.question]}
                  control={control}
                  label={item.question}
                  name={item.id.toString()}
                />
              </Grid>
            );

          default:
            return null; // or handle the default case accordingly
        }
      })}
    </>
  );
};
