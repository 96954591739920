import { Controller, useController } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import {
  DateTimePicker,
  MobileDatePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { StyledTextField } from "components/SearchBar/Styles";
import { colors } from "constants/themeConstants";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useGetTermsQuery } from "features/tos/tosApiSlice";
import { useState } from "react";
import ModalWrapper from "components/ModalWrapper";
import RichTextViewer from "components/richText/RichTextViewer";
import { MuiTelInput } from "mui-tel-input";
import { AiOutlinePicture } from "react-icons/ai";
export const FastTextField = ({
  name,
  type,
  required,
  control,
  label,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <>
          <label htmlFor={label}>
            {required ? "*" : ""}
            {label}
          </label>
          <StyledTextField
            id={label}
            type={type}
            sx={{
              my: 1,
              mr: 3,
              height: "4rem",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: colors.background.tertiary,
                  backgroundColor: "none",
                  outline: "none",
                },
                "&:hover fieldset": {
                  borderColor: colors.background.secondary,
                  backgroundColor: "none",
                  outline: "none",
                },
                "&.Mui-focused fieldset": {
                  borderColor: colors.background.secondary,
                  backgroundColor: "none",
                  outline: "none",
                },
                "& input": {
                  color: colors.background.tertiary,
                },
              },
            }}
            fullWidth
            variant="outlined"
            required={required}
            placeholder={label}
            // label={
            //   <Typography ml={2} fontFamily="Campton">
            //     {label}
            //   </Typography>
            // }
            {...field}
            error={!!error}
            helperText={error ? error.message : null}
            {...rest}
          />
        </>
      )}
    />
  );
};
export const FastPhoneField = ({
  name,
  type,
  required,
  control,
  label,
  ...rest
}) => {
  return (
    <>
      <label htmlFor={label}>
        {required ? "*" : ""}
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error }, formState }) => (
          <MuiTelInput
            id={label}
            sx={{
              my: 1,
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: colors.background.outline,
                },
                "&:hover fieldset": {
                  borderColor: colors.background.secondary,
                },
                "&.Mui-focused fieldset": {
                  borderColor: colors.background.secondary,
                },
                color: colors.background.tertiary,
              },
              "& input": {
                color: colors.background.tertiary,
              },
            }}
            // label={
            //   <Typography ml={2} fontFamily="Campton">
            //     {label}
            //   </Typography>
            // }

            placeholder={label}
            defaultCountry="ET"
            onlyCountries={["ET"]}
            {...field}
            fullWidth
            variant="outlined"
            required={required}
            error={!!error}
            helperText={error ? error.message : null}
            {...rest}
          />
        )}
      />
    </>
  );
};

export const FastTextAreaField = ({
  name,
  control,
  label,
  required,
  ...rest
}) => {
  const multiline = true;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <TextField
          fullWidth
          variant="standard"
          sx={{ my: 1, color: colors.background.tertiary }}
          label={label}
          {...field}
          error={!!error}
          helperText={error ? error.message : null}
          multiline={multiline}
          minRows={multiline ? 3 : null}
          maxRows={multiline ? 10 : null}
          {...rest}
        />
      )}
    />
  );
};

export const FastSwitchField = ({
  name,
  control,
  label,
  required,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={field.value}
                onChange={field.onChange}
                {...rest}
              />
            }
            label={label}
            error={!!error}
            helperText={error ? error.message : null}
          />
        </FormGroup>
      )}
    />
  );
};

export const FastCheckboxField = ({
  name,
  control,
  required,
  label,
  options,
  ...rest
}) => {
  const { data: termsText } = useGetTermsQuery();
  const [modalPayload, setModalPayload] = useState({ open: false, text: "" });
  return (
    <>
      <Controller
        name={name}
        defaultValue={[]}
        control={control}
        render={({ field, fieldState: { error }, formState }) => (
          <FormControl
            fullWidth
            variant="standard"
            error={!!error}
            helperText={error ? error.message : null}
            sx={{ my: 1 }}
            {...rest}
          >
            {/* <FormLabel component="legend">{label}</FormLabel> */}
            <Grid container width="100%">
              <FormGroup>
                {options.map((item) => (
                  <Grid key={item} item xs={12}>
                    <FormControlLabel
                      key={item}
                      control={
                        <Box
                          // onClick={() => toggleValue(item.name)}
                          sx={{
                            padding: "10px",
                            width: "100%",
                            cursor: "pointer",
                            // '&:hover': {
                            //   border: `2px solid ${colors.background.secondary}`,
                            // },
                            // border: `${field.value.some(obj => obj.name === item.name) ? '2px' : '1px'} solid ${
                            //   colors.background.secondary
                            // }`,
                            // backgroundColor: field.value.some(obj => obj.name === item.name) ?
                            //   colors.background.secondary:'none',
                            borderRadius: "20px",
                          }}
                        >
                          <Checkbox
                            checked={field.value.some((obj) => obj === item)}
                            onChange={(event) => {
                              const newValue = event.target.checked
                                ? [...field.value, item]
                                : field.value.filter((value) => value !== item);

                              field.onChange(newValue);
                            }}
                            name={"accept"}
                          />
                          <Typography
                            color={colors.background.tertiary}
                            fontFamily={"Comfortaa"}
                            display={"inline"}
                            fontSize={"14px"}
                            textAlign={"center"}
                            fontWeight={"500"}
                            // alt={item.value}
                            // style={{
                            //   objectFit: 'cover',
                            //   width: 'auto',
                            //   height: '3rem',
                            // }}
                          >
                            {"I accept the "}
                            <span
                              onClick={() =>
                                setModalPayload({
                                  text: termsText ? item : "Loading...",
                                  open: true,
                                  // toggleAccepted: () => setTermsAccepted((prev) => !prev),
                                  // accepted: termsAccepted,
                                })
                              }
                              style={{
                                textDecoration: "underline",
                                color: colors.background.tertiary,
                                fontFamily: "Campton",
                                fontSize: "14px",
                              }}
                            >
                              {"Terms and Conditions"}
                            </span>
                          </Typography>
                        </Box>
                      }
                      // label={item?.name}
                    />
                  </Grid>
                ))}
              </FormGroup>
            </Grid>
          </FormControl>
        )}
      />
      <Modal
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        open={modalPayload.open}
        onClose={() =>
          setModalPayload({
            open: false,
            text: "",
          })
        }
      >
        <ModalWrapper>
          <Box>
            <RichTextViewer
              readOnly={true}
              value={modalPayload.text}
              setFieldValue={() => {}}
              placeholder=""
            />
          </Box>
        </ModalWrapper>
      </Modal>
    </>
  );
};
export const FastSelectFieldSingle = ({
  name,
  control,
  label,
  required,
  options,
  inputColor, // New prop for specifying input color
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error} sx={{ my: 1 }}>
          {/* <InputLabel style={{ fontFamily: "Campton" }} id={`${name}Label`}>
            {label}
          </InputLabel> */}
          <label htmlFor={label}>
            {required ? "*" : ""}
            {label}
          </label>
          <Select
            id={label}
            fullWidth
            variant="standard"
            required={required}
            label={label}
            value={value || ""}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            displayEmpty
            inputProps={{
              style: {
                color: colors.background.tertiary || colors.background.tertiary,
              }, // Apply the input color
            }}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Typography variant="body1">{label}</Typography>;
              }

              return selected;
            }}
            {...rest}
          >
            {options.map((item) => (
              <MenuItem
                style={{
                  fontFamily: "Campton",
                  color: colors.background.tertiary,
                }}
                key={item}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export const FastSelectField = ({
  name,
  control,
  required,
  label,
  options,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error} sx={{ my: 1 }}>
          <InputLabel
            style={{ fontFamily: "Campton", marginLeft: 2 }}
            id={`${name}Label`}
          >
            {label}
            {/* <span style={{color:'red', fontSize:12}}>{required&& '  *required'}</span> */}
          </InputLabel>
          <Select
            fullWidth
            variant="standard"
            multiple
            required={required}
            label={label}
            value={value || []}
            onChange={(event) => {
              onChange(event.target.value);
            }}
            inputProps={{
              style: {
                color: colors.background.tertiary || colors.background.tertiary,
              },
            }}
            {...rest}
          >
            {options.map((item) => (
              <MenuItem
                style={{
                  fontFamily: "Campton",
                  color: colors.background.tertiary,
                }}
                key={item}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export const FastDateField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <MobileDatePicker
          InputProps={{
            variant: "standard",
          }}
          sx={{ my: 1 }}
          label={label}
          {...field}
          {...rest}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : null,
              ...rest,
            },
          }}
        />
      )}
    />
  );
};

export const FastDateTimeField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <DateTimePicker
          InputProps={{
            variant: "standard",
          }}
          sx={{ my: 1 }}
          label={label}
          {...field}
          {...rest}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : null,
              ...rest,
            },
          }}
        />
      )}
    />
  );
};

export const FastTimeField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <TimePicker
          InputProps={{
            variant: "standard",
          }}
          sx={{ my: 1 }}
          label={label}
          {...field}
          {...rest}
          slotProps={{
            textField: {
              variant: "standard",
              fullWidth: true,
              error: !!error,
              helperText: error ? error.message : null,
              ...rest,
            },
          }}
        />
      )}
    />
  );
};
export const FileUploadField = ({ name, control, label, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error }, formState }) => (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center", // Align vertically to the center
              justifyContent: "start",
            }}
          >
            <Box
              variant="text"
              component="label"
              sx={{
                my: 3,
                width: "90%",
                // border: `1px solid ${colors.background.outline}`,
                color: colors.background.outline,
                textTransform: "none",
                fontFamily: "Campton",
              }}
            >
              <AttachFileIcon
                sx={{
                  width: "18px",
                  height: "18px",
                  color: colors.background.tertiary,
                }}
              />{" "}
              &nbsp;&nbsp;
              <Typography variant="p" color={colors.background.tertiary}>
                {label}
              </Typography>
              <input
                hidden
                required
                accept={true}
                type="file"
                onChange={(e) => field.onChange(e.target.files[0])}
              />
            </Box>
            <Typography sx={{ ml: 2 }}>{field.value?.name || ""}</Typography>
          </Box>
        </>
      )}
    />
  );
};
// import React, { useState } from "react";

export const ImageUploadField = ({ name, label, control, required }) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required },
    defaultValue: "",
  });

  const [imageData, setImageData] = useState(null);
  const [ImageUpload, setimageUpload] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setimageUpload(file);
    if (!file) {
      return; // Handle empty file selection gracefully (optional)
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const imageDataUrl = reader.result;
      const base64Index = imageDataUrl.indexOf("base64,") + "base64,".length;
      const imageData = imageDataUrl.substring(base64Index);
      setImageData(imageData);
      onChange(imageData);
    };
    reader.onerror = (error) => {
      console.error("Error:", error);
    };
  };
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        {" "}
        {/* Use full width for text field */}
        <label htmlFor={label}>
          {required ? "*" : ""}
          {label}
        </label>
        <TextField
          onClick={() => document.getElementById(name).click()}
          id={label}
          fullWidth
          placeholder={label}
          // label={label}
          value={imageData ? ImageUpload.name : value || ""} // Display file name or placeholder
          InputProps={{
            style: { width: "100%" },

            // style: { width: "410px" },
            readOnly: true, // Prevent direct editing of text field
            endAdornment: (
              <IconButton
                component="span"
                onClick={() => document.getElementById(name).click()}
              >
                <AiOutlinePicture style={{ marginLeft: "8px" }} />
              </IconButton>
            ),
          }}
        />
      </Grid>
      <input
        accept="image/*"
        id={name}
        type="file"
        onChange={handleImageChange}
        onBlur={onBlur}
        style={{ display: "none" }}
      />
      {imageData && (
        <Grid item xs={12}>
          {" "}
          {/* Allow full width for success message */}
          <Typography variant="body1" color="success">
            Image uploaded successfully!
          </Typography>
        </Grid>
      )}
      {invalid && (
        <Grid item xs={12}>
          {" "}
          {/* Allow full width for error message */}
          <Typography variant="body1" color="error">
            {error?.message || "Required"}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
// export const FastSelectField = ({ name, control, label, options, ...rest }) => {

//   return (
//     <Controller
//       name={name}
//       control={control}
//       render={({ field: { value, onChange }, fieldState: { error } }) => (
//         <FormControl
//           fullWidth
//           variant="standard"
//           error={!!error}
//           helperText={error ? error.message : null}
//           sx={{ my: 1 }}
//           {...rest}
//         >
//           <InputLabel id={`${name}Label`}>{label}</InputLabel>
//           <Select
//             fullWidth
//             multiple // Enable multiple selection
//             variant="standard"
//             label={label}
//             value={value || []} // Use the value prop provided by react-hook-form
//             // onChange={(event) => {
//             //   // Convert the selected options into an array
//             //   const selectedOptions = Array.from(
//             //     event.target.selectedOptions,
//             //     (option) => option.value
//             //   );
//             //   onChange(selectedOptions); // Update the field value with the array
//             // }}
//             onChange={(event) => {
//               console.log('event')
//               const selectedOptions = event.target.value; // Selected values will be an array
//               onChange(selectedOptions); // Update the form field's value
//             }}
//           >
//             {options.map((item) => (
//               <MenuItem key={item.id} value={item.id}>
//                 {item?.name || item}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       )}
//     />
//   );
//     // <Controller
//     //   name={name}
//     //   control={control}
//     //   render={({ field, fieldState: { error }, formState }) => (
//     //     <FormControl
//     //       fullWidth
//     //       variant="standard"
//     //       error={!!error}
//     //       helperText={error ? error.message : null}
//     //       sx={{ my: 1 }}
//     //       {...rest}
//     //     >
//     //       <InputLabel id={`${name}Label`}>{label}</InputLabel>
//     //       <Select
//     //         fullWidth
//     //         // multiple
//     //         variant="standard"
//     //         label={label}
//     //         {...field}
//     //         {...rest}
//     //       >
//     //         {options.map((item) => (
//     //           <MenuItem
//     //             value={item}
//     //           >
//     //             {item?.name || item}
//     //           </MenuItem>
//     //         ))}
//     //       </Select>
//     //     </FormControl>
//     //   )}
//     // />

// };
